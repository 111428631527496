































import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({ name: "ticket-files" })
export default class TicketFiles extends Vue {
  /* Constants */

  @Prop({ default: null }) readonly value!: string;

  /* Variables */

  /* Computed */

  /* Methods */

  /* Life-cycle Methods */

  @Emit("input")
  close() {
    return false;
  }
}
